<template>
  <div class="box-wrap-max">
    <!--    轮播图 -->
    <div class="swiper index-swiper pc-box">
      <div class="swiper-wrapper">
        <!--        1-->
        <div class="swiper-slide">
          <img
            style="height: 80rem"
            src="../../assets/index/banner0104@2x.png"
            alt=""
          />
          <div class="box-wrap-min pos-a df df-jc-sb banner-wrap">
            <div class="banner-l" style="margin-top: 15rem">
              <img
                :class="animateClass[0].l"
                style="width: 56rem"
                src="../../assets/index/banner0101@2x.png"
                alt=""
                @click="playVideo({}, 0, '好粮产品篇_batch.mp4')"
              />
            </div>
            <div class="banner-r" style="margin-top: 6rem">
              <img
                :class="animateClass[0].r"
                style="width: 60rem"
                src="../../assets/index/banner0102@2x.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <!--        2-->
        <div class="swiper-slide">
          <img
            style="height: 80rem"
            src="../../assets/index/banner0203@2x.png"
            alt=""
          />
          <div class="box-wrap-min pos-a df df-jc-sb banner-wrap">
            <div class="banner-l" style="margin-top: 6rem">
              <img
                :class="animateClass[1].l"
                style="width: 38rem"
                src="../../assets/index/banner0202@2x.png"
                alt=""
              />
            </div>
            <div class="banner-r" style="margin-top: 15rem">
              <img
                :class="animateClass[1].r"
                style="width: 59.3rem"
                src="../../assets/index/banner0201@2x.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-navigation-prev pc-box">
        <img
          src="../../assets/index/topButton-turnL@2x.png"
          style="width: 4rem"
          alt=""
        />
      </div>
      <div class="swiper-navigation-next pc-box">
        <img
          src="../../assets/index/topButton-turnR@2x.png"
          style="width: 4rem"
          alt=""
        />
      </div>
      <div class="swiper-pagination pc-box"></div>
    </div>
    <div class="swiper index-swiper h5-box">
      <div class="swiper-wrapper">
        <!--        1-->
        <div class="swiper-slide">
          <img
            style="height: 38rem"
            src="../../assets/index/banner0104@2x.png"
            alt=""
          />
          <div class="box-wrap-min pos-a df df-jc-c df-ai-fs banner-wrap-h5">
            <div class="banner-l" style="margin-top: 7.3rem">
              <img
                src="../../assets/index/banner0101@2x.png"
                alt=""
                @click="playVideo({}, 0, '好粮产品篇_batch.mp4')"
              />
            </div>
            <div class="banner-r" style="margin-top: 6rem">
              <img src="../../assets/index/banner0102@2x.png" alt="" />
            </div>
          </div>
        </div>

        <!--        2-->
        <div class="swiper-slide">
          <img
            style="height: 38rem"
            src="../../assets/index/banner0203@2x.png"
            alt=""
          />
          <div class="box-wrap-min pos-a df df-jc-c df-ai-fs banner-wrap-h5">
            <div class="banner-l" style="margin-top: 6rem">
              <img src="../../assets/index/banner0202@2x.png" alt="" />
            </div>
            <div class="banner-r" style="margin-top: 10rem">
              <img src="../../assets/index/banner0201@2x.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    吉林一亩好粮视频介绍 -->
    <div class="box-wrap-min df df-jc-sb four-div">
      <div
        class="four-div-item pos-r"
        v-for="(item, index) in fourDivList"
        :key="index"
        @mouseenter="fourDivIndex = index"
        @mouseleave="fourDivIndex = -1"
      >
        <img
          :src="fourDivIndex === index ? item.bgImg2 : item.bgImg"
          alt=""
          class="bg"
        />
        <div
          class="four-div-item__content pos-a"
          :class="fourDivIndex === index ? 'active' : ''"
        >
          <div class="title fz20 fwb">{{ item.title }}</div>
          <div class="desc fz-desc-color fz14">{{ item.desc }}</div>
          <div
            class="btn"
            @click="playVideo(item, index, '壹亩好粮宣传片_batch.mp4')"
          >
            <span>{{ item.btn }}</span>
            <img
              style="width: 18px; height: 18px"
              :src="fourDivIndex === index ? item.btnImg2 : item.btnImg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="videoDialog"
      class="video-dialog"
      @click.self="videoDialog = false"
    >
      <!--      <div class="close">
        <span @click="videoDialog = false">×</span>
      </div>-->

      <div class="video-wrap">
        <video id="indexVideo" autoplay controls :src="videoSrc"></video>
      </div>
    </div>

    <!--    粮食无忧 服务更优 -->
    <div class="box-wrap-min">
      <div class="main-page-title-wrap">
        <div class="main-page-title">
          <span class="line"></span>
          <span class="text">粮食无忧 服务更优</span>
          <span class="line"></span>
        </div>
        <div class="main-page-desc">
          严格把关只为给您放心粮食，热情服务只为给您舒心体验
        </div>
      </div>

      <div class="lswy-wrap df df-jc-sb">
        <div class="lswy-item">
          <img
            style="width: 18rem"
            src="../../assets/index/adorn-icon-jxlc@2x.png"
            alt=""
          />
          <p class="fz20 fz-title-color fwb">精选粮仓</p>
          <p class="line"></p>
          <p class="fz14 fz-desc-color">
            谷道合精选五大粮食生产基地的优质谷物，只为给您顶级粮品，让您买的放心，吃的安心。
          </p>
          <p class="line-b"></p>
        </div>
        <div class="lswy-item">
          <img
            style="width: 18rem"
            src="../../assets/index/adorn-icon-xnxm@2x.png"
            alt=""
          />
          <p class="fz20 fz-title-color fwb">现碾鲜米</p>
          <p class="line"></p>
          <p class="fz14 fz-desc-color">
            拒绝陈米，只选当年生产的新稻谷，无添加、无抛光、无打蜡，只为保留现碾米的自然鲜。
          </p>
          <p class="line-b"></p>
        </div>
        <div class="lswy-item">
          <img
            style="width: 18rem"
            src="../../assets/index/adorn-icon-yygw@2x.png"
            alt=""
          />
          <p class="fz20 fz-title-color fwb">营养顾问</p>
          <p class="line"></p>
          <p class="fz14 fz-desc-color">
            专业营养师团队，为您解答健康养生秘诀，针对不同用户身体情况，制定专属养生计划方案。
          </p>
          <p class="line-b"></p>
        </div>
        <div class="lswy-item">
          <img
            style="width: 18rem"
            src="../../assets/index/adorn-icon-qcps@2x.png"
            alt=""
          />
          <p class="fz20 fz-title-color fwb">全城配送</p>
          <p class="line"></p>
          <p class="fz14 fz-desc-color">
            谷道合支持全城免费配送服务，59分钟送货上门，足不出户，轻松选购新鲜谷物，我们愿意做好粮食的搬运工。
          </p>
          <p class="line-b"></p>
        </div>
      </div>
    </div>

    <!--    生态完善 产品众多-->
    <div class="box-wrap-max">
      <div class="main-page-title-wrap">
        <div class="main-page-title">
          <span class="line"></span>
          <span class="text">生态完善 产品众多</span>
          <span class="line"></span>
        </div>
        <div class="main-page-desc">
          谷道合拥有完善的品牌生态系统，众多好产品，满足各类人群需求
        </div>
      </div>

      <div class="stws-wrap">
        <div class="stws-div box-wrap-min">
          <div class="stws-item">
            <div class="img-wrap">
              <img
                style="width: 26.4rem"
                src="../../assets/index/stws-img-jxlc@2x.png"
                alt=""
              />
            </div>
            <div class="content">
              <img src="../../assets/index/stws-adorn-jxlc@2x.png" alt="" />
              <p class="fz20 fwb fz-title-color">
                谷道合线下营销体验店，鲜谷专卖、营养顾问
              </p>
              <p class="line"></p>

              <div class="desc-wrap">
                <span class="point"></span>
                <span class="fz14 fz-desc-color">
                  新鲜谷物直营，纯天然营养健康；
                </span>
              </div>

              <div class="desc-wrap">
                <span class="point">.</span>
                <span class="fz14 fz-desc-color">
                  专业营养师为您提供免费咨询服务；
                </span>
              </div>

              <div class="desc-wrap">
                <span class="point">.</span>
                <span class="fz14 fz-desc-color"> 为家庭储备新鲜好粮 </span>
              </div>

              <div class="btn">马上咨询</div>
            </div>
          </div>
          <div class="stws-item">
            <div class="img-wrap">
              <img
                style="width: 26.4rem"
                src="../../assets/index/stws-img-ydnmz@2x.png"
                alt=""
              />
            </div>
            <div class="content">
              <img src="../../assets/index/stws-adorn-ydnmz@2x.png" alt="" />
              <p class="fz20 fwb fz-title-color">
                谷道合线下多功能碾米车，新鲜米就在家门口
              </p>
              <p class="line"></p>

              <div class="desc-wrap">
                <span class="point"></span>
                <span class="fz14 fz-desc-color">
                  可定点、可移动，功能齐全、方便高效；
                </span>
              </div>

              <div class="desc-wrap">
                <span class="point">.</span>
                <span class="fz14 fz-desc-color">
                  鲜米现碾，保留大米最大营养；
                </span>
              </div>

              <div class="desc-wrap">
                <span class="point">.</span>
                <span class="fz14 fz-desc-color">
                  移动作业，解除空间禁锢。
                </span>
              </div>

              <div class="btn">马上咨询</div>
            </div>
          </div>
          <div class="stws-item">
            <div class="img-wrap">
              <img
                style="width: 26.4rem"
                src="../../assets/index/stws-img-ssbf@2x.png"
                alt=""
              />
            </div>
            <div class="content">
              <img src="../../assets/index/stws-adorn-ssbf@2x.png" alt="" />
              <p class="fz20 fwb fz-title-color">
                谷道合美味拌饭，营养健康，足不出户轻松尝鲜
              </p>
              <p class="line"></p>

              <div class="desc-wrap">
                <span class="point"></span>
                <span class="fz14 fz-desc-color">
                  食材新鲜，美味、健康、营养；；
                </span>
              </div>

              <div class="desc-wrap">
                <span class="point">.</span>
                <span class="fz14 fz-desc-color">
                  营养搭配，更符合大众口味的中式拌饭；
                </span>
              </div>

              <div class="desc-wrap">
                <span class="point">.</span>
                <span class="fz14 fz-desc-color"> 支持配送，送货上门。 </span>
              </div>

              <div class="btn">马上咨询</div>
            </div>
          </div>
          <div class="stws-item">
            <div class="img-wrap">
              <img
                style="width: 26.4rem"
                src="../../assets/index/stws-img-yszp@2x.png"
                alt=""
              />
            </div>
            <div class="content">
              <img src="../../assets/index/stws-adorn-yszp@2x.png" alt="" />
              <p class="fz20 fwb fz-title-color">
                谷道合养生粥，帮你订制健康养生计划
              </p>
              <p class="line"></p>

              <div class="desc-wrap">
                <span class="point"></span>
                <span class="fz14 fz-desc-color">
                  专业营养师搭配，鲜米鲜谷，飘香四溢；
                </span>
              </div>

              <div class="desc-wrap">
                <span class="point">.</span>
                <span class="fz14 fz-desc-color">
                  多种营养搭配，口味佳、营养佳、健康佳；
                </span>
              </div>

              <div class="desc-wrap">
                <span class="point">.</span>
                <span class="fz14 fz-desc-color">
                  搭配指导，人人都可煮出一锅好粥。
                </span>
              </div>

              <div class="btn">马上咨询</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    五大基地 肥田沃地 -->
    <div class="box-wrap-max">
      <div class="main-page-title-wrap">
        <div class="main-page-title">
          <span class="line"></span>
          <span class="text">五大基地 肥田沃地</span>
          <span class="line"></span>
        </div>
        <div class="main-page-desc">
          谷道合精选五大粮食生产基地的新鲜稻谷，只为给您优质好粮
        </div>
      </div>

      <div
        class="wdjd-wrap"
        :style="{
          backgroundImage: `url(${fiveBase[fiveBaseIndex].content.bgImg})`,
        }"
      >
        <!--        pc样式-->
        <div class="wdjd-div box-wrap-min pc-box-flex">
          <div class="wdjd-div-l">
            <div
              class="img-wrap"
              v-for="(item, index) in fiveBase"
              :key="index"
              :style="{
                border: fiveBaseIndex === index ? '4px solid #ffffff' : 'none',
              }"
              @mouseenter="fiveBaseIndex = index"
            >
              <div class="img-wrap">
                <img
                  :src="
                    require(`../../assets/index/wdjd-img${
                      index === fiveBaseIndex ? '' : '-back'
                    }-${item.img}@2x.${
                      index === fiveBaseIndex ? 'png' : 'jpg'
                    }`)
                  "
                  alt=""
                />
              </div>
              <div class="content">
                <div class="fz24 fz-title-color-active">{{ item.name }}</div>
                <div class="fz12 fz-desc-color-active" v-html="item.desc"></div>
              </div>
            </div>
          </div>
          <div class="wdjd-div-r">
            <div class="title fz24 fz-title-color fwb">
              {{ fiveBase[fiveBaseIndex].content.name }}
            </div>
            <div class="address">
              <span class="fz24 fz-theme-color fwb">{{
                fiveBase[fiveBaseIndex].content.title
              }}</span>
              <span
                class="fz14 fz-desc-color"
                v-html="fiveBase[fiveBaseIndex].content.desc"
              ></span>
            </div>
            <div class="line"></div>
            <div
              class="desc-wrap"
              v-for="(vItem, vIndex) in fiveBase[fiveBaseIndex].content.list"
              :key="vIndex"
            >
              <span class="point">.</span>
              <span class="fz14 fz-desc-color">
                {{ vItem.desc }}
              </span>
            </div>
          </div>
        </div>

        <!--        h5样式 -->
        <div class="wdjd-div box-wrap-min h5-box">
          <div class="wdjd-div-l df df-jc-sb df-ai-c fd-row">
            <div
              v-for="(item, index) in fiveBase"
              :key="index"
              :style="{
                opacity: fiveBaseIndex === index ? '1' : '0.5',
              }"
              class="fz12 fz-title-color-active"
              @click="fiveBaseIndex = index"
            >
              {{ item.name }}
            </div>
          </div>

          <div class="wdjd-div-r">
            <div class="title fz14 fz-title-color fwb">
              {{ fiveBase[fiveBaseIndex].content.name }}
            </div>
            <div class="address">
              <span class="fz14 fz-theme-color fwb" style="flex: 0 0 120px">{{
                fiveBase[fiveBaseIndex].content.title
              }}</span>
              <span
                class="fz10 fz-desc-color"
                style="font-size: 8px"
                v-html="fiveBase[fiveBaseIndex].content.desc"
              ></span>
            </div>
            <div class="line"></div>
            <div
              class="desc-wrap"
              v-for="(vItem, vIndex) in fiveBase[fiveBaseIndex].content.list"
              :key="vIndex"
            >
              <span class="point">.</span>
              <span class="fz10 fz-desc-color">
                {{ vItem.desc }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 招商加盟 共创未来 -->
    <div class="box-wrap-min zsjm-wrap">
      <div class="zsjm-wrap-l">
        <div class="fz40 fwb fz-title-color">招商加盟 共创未来</div>
        <div class="fz16 fz-desc-color mt20">
          来吧！加入谷道合，携手谷道合顶尖智囊团，共创谷物新模式经济奇迹
        </div>
        <div class="line mt20"></div>
        <div class="fz16 fz-desc-color mt30">谷道合优势：</div>

        <div class="five-div-wrap mt20">
          <div class="five-div">
            <div class="fz-theme-color fz44 fwb">01.</div>
            <div class="fz-title-color fz18 fwb mt10">完整的服务体系</div>
          </div>
          <div class="five-div">
            <div class="fz-theme-color fz44 fwb">02.</div>
            <div class="fz-title-color fz18 fwb mt10">精准的客户流</div>
          </div>
          <div class="five-div">
            <div class="fz-theme-color fz44 fwb">03.</div>
            <div class="fz-title-color fz18 fwb mt10">独特的运营模式</div>
          </div>
          <div class="five-div">
            <div class="fz-theme-color fz44 fwb">04.</div>
            <div class="fz-title-color fz18 fwb mt10">专业的平台支持</div>
          </div>
          <div class="five-div">
            <div class="fz-theme-color fz44 fwb">05.</div>
            <div class="fz-title-color fz18 fwb mt10">诚挚的服务体验</div>
          </div>
        </div>
      </div>
      <div class="zsjm-wrap-r">
        <img src="../../assets/index/zsjm-img-ewm@2x.png" alt="" />
      </div>
    </div>

    <!-- 量身打造 功能便捷 -->
    <div class="box-wrap-max">
      <div class="main-page-title-wrap">
        <div class="main-page-title">
          <span class="line"></span>
          <span class="text">量身打造 功能便捷</span>
          <span class="line"></span>
        </div>
        <div class="main-page-desc">
          用户版、商户版、微信、小程序，只为满足您个性化使用习惯
        </div>
      </div>

      <div class="lsdz-wrap">
        <div class="lsdz-div box-wrap-min">
          <div class="lsdz-div-l">
            <img
              class="pc-box"
              ref="shoujiImg"
              src="../../assets/index/lsdz-img-shouji@2x.png"
              alt=""
            />
            <img
              class="h5-box"
              src="../../assets/index/lsdz-img-shouji2@2x.png"
              alt=""
            />
          </div>
          <div class="lsdz-div-r">
            <div class="yhb">
              <div class="title-wrap">
                <div class="fz30 fz-title-color-active title">用户版</div>
                <div
                  class="fz14 fz-title-color-active desc"
                  style="margin-left: 2rem"
                >
                  适合大众用户使用，支持营销活动、下单取货、积分商城、分销推广等功能。
                </div>
              </div>

              <div class="content mt30">
                <div class="btn">PC版</div>
                <div class="btn">H5版</div>
                <div class="btn">小程序版</div>
              </div>
            </div>
            <div class="yhb">
              <div class="title-wrap" style="margin-top: 10rem">
                <div class="fz30 fz-title-color-active title">商户版</div>
                <div
                  class="fz14 fz-title-color-active desc"
                  style="margin-left: 2rem"
                >
                  适用店铺营销、线上商城、外卖订单、人员管理等场景，支持多店统一管理、批量发单、一键发单等功能。
                </div>
              </div>

              <div class="content mt30">
                <div class="btn">PC版</div>
                <div class="btn">H5版</div>
                <div class="btn">小程序版</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",

  data() {
    return {
      fourDivIndex: -1,
      fourDivList: [
        {
          bgImg: require("../../assets/index/sgjs-img-back01@2x.png"),
          bgImg2: require("../../assets/index/sgjs-img-back02@2x.png"),
          title: "吉林一亩好粮视频介绍",
          desc: "好土地，产好粮，能养一方人",
          btn: "点击播放",
          btnImg: require("../../assets/index/sgjs-icon-djbf01@2x.png"),
          btnImg2: require("../../assets/index/sgjs-icon-djbf02@2x.png"),
        },
        {
          bgImg: require("../../assets/index/sgjs-img-back01@2x.png"),
          bgImg2: require("../../assets/index/sgjs-img-back02@2x.png"),
          title: "加入谷道合一起创未来",
          desc: "我们期待与您共同前行",
          btn: "马上加入",
          btnImg: require("../../assets/index/sgjs-icon-gd01@2x.png"),
          btnImg2: require("../../assets/index/sgjs-icon-gd02@2x.png"),
        },
        {
          bgImg: require("../../assets/index/sgjs-img-back01@2x.png"),
          bgImg2: require("../../assets/index/sgjs-img-back02@2x.png"),
          title: "什么才是真正的好粮食？",
          desc: "了解好粮食，掌握辨别好粮食的方法",
          btn: "点击查看",
          btnImg: require("../../assets/index/sgjs-icon-gd01@2x.png"),
          btnImg2: require("../../assets/index/sgjs-icon-gd02@2x.png"),
        },
        {
          bgImg: require("../../assets/index/sgjs-img-back01@2x.png"),
          bgImg2: require("../../assets/index/sgjs-img-back02@2x.png"),
          title: "联系在线客服了解谷道合",
          desc: "24小时在线客服，持续为您服务",
          btn: "马上联系",
          href: "https://chat56.live800.com/live800/chatClient/chatbox.jsp?companyID=1552092&configID=581628&jid=2206739603&s=1",
          btnImg: require("../../assets/index/sgjs-icon-gd01@2x.png"),
          btnImg2: require("../../assets/index/sgjs-icon-gd02@2x.png"),
        },
      ],

      fiveBaseIndex: 0,
      fiveBase: [
        {
          name: "吉林基地",
          desc: "东经：121°38′～131°19′&nbsp;&nbsp;&nbsp;&nbsp;北纬：40°52′～46°18′",
          img: "jljd",
          content: {
            name: "吉林基地",
            title: "松花江上游黑土田",
            desc: "东经：121°38′～131°19′&nbsp;&nbsp;&nbsp;&nbsp;北纬：40°52′～46°18′",
            bgImg: require("../../assets/index/wdjd-img-back-jljd@2x.jpg"),
            list: [
              {
                desc: "黄金水源带，黑土层深达40厘米至100厘米，富含氮、磷、钾各种植物营养元素，有机质含量约是黄土的10倍，为上等水稻田；",
              },
              {
                desc: "吉林境内水系发达，由松花江、饮马河支流水系组成，流域面积占总面积的84%，丰沛的水资源滋润了广袤的黑土地；",
              },
              {
                desc: "大自然给予的独特资源创造了最有利于粳稻生长的原生态环境。",
              },
            ],
          },
        },
        {
          name: "松原基地",
          desc: "东经：123°6′～126°11′ 至 北纬：43°59′～45°32′",
          img: "syjd",
          content: {
            name: "松原基地",
            title: "查干湖湿地营养田",
            desc: "东经：123°6′～126°11′&nbsp;&nbsp;&nbsp;&nbsp;北纬：43°59′～45°32′",
            bgImg: require("../../assets/index/wdjd-img-back-syjd@2x.jpg"),
            list: [
              {
                desc: "地处查干湖国家级自然保护区内，2018年，习总书记曾亲赴松原考察。作为世界粮食黄金生产带，松原充分发挥出了湿地中的营养田在农业种植方面的重要作用；",
              },
              {
                desc: "跨年休耕的黑土地，土壤肥沃原生态，长达150天的生长期，2900小时长日照，3100度的高积温，为生产高品质的“查干湖大米”提供了得天独厚的条件。",
              },
            ],
          },
        },
        {
          name: "五常基地",
          desc: "东经：126°33′～128°14′ 至 北纬：44°04′～45°26′",
          img: "wcjd",
          content: {
            name: "五常基地",
            title: "牤牛河畔良种田",
            desc: "东经：126°33′～128°14′&nbsp;&nbsp;&nbsp;&nbsp;北纬：44°04′～45°26′",
            bgImg: require("../../assets/index/wdjd-img-back-wcjd@2x.jpg"),
            list: [
              {
                desc: "黑土层平均厚度达100厘米左右，含有机物高达10%以上，堪称膏秧圣土；",
              },
              {
                desc: "五常水稻品种是根据五常的自然条件自己培育的优良品种，五常系列优质品种为优质稻米的持续开发打下了基础；",
              },
              {
                desc: "五常自然生态环境优良，大气环境达到了国家制定的生产绿色食品的标准；",
              },
              {
                desc: "三大优势的奠定使得五常大米成为了全国最好吃的大米之一。",
              },
            ],
          },
        },
        {
          name: "通榆基地",
          desc: "东经：121°38″～124°22′ 至 北纬：44°13′57″～46°18′ ",
          img: "tyjd",
          content: {
            name: "通榆基地",
            title: "杂粮碱都伴侣田",
            desc: "东经：121°38″～124°22′&nbsp;&nbsp;&nbsp;&nbsp;北纬：44°13′57″～46°18′",
            bgImg: require("../../assets/index/wdjd-img-back-tyjd@2x.jpg"),
            list: [
              {
                desc: "通榆基地拥有“杂粮碱都”的称号，气候属温带大陆性季风气候，四季分明，雨热同期，无霜期144天，积温2996.2℃，年平均日照时数为2915小时",
              },
              {
                desc: "水资源丰富，境内有向海、兴隆、胜利三座中型水库及683个自然泡沼；",
              },
              {
                desc: "不可复制的地理、气候环境为多种特色杂粮谷物，提供了最优质的生长条件，最深层的滋养。",
              },
            ],
          },
        },
        {
          name: "梅河基地",
          desc: "东经：125°15′～126°03′ 至 北纬：42°08′～43°02′",
          img: "mhjd",
          content: {
            name: "梅河基地",
            title: "大柳河天然富硒田",
            desc: "东经：125°15′～126°03′&nbsp;&nbsp;&nbsp;&nbsp;北纬：42°08′～43°02′",
            bgImg: require("../../assets/index/wdjd-img-back-mhjd@2x.jpg"),
            list: [
              {
                desc: "吉林省天然富硒带水稻主产区，土壤为草甸型黑土，PH值6.35—6.70，质地疏松通透性良好，土质肥沃，含有作物生长必需的多种微量元素，保证了水稻生长的各种养分需求；",
              },
              {
                desc: "全年日照时数2556小时，无霜期为137天~145天，有效积温为2960℃，雨热同季。优质的富硒土壤造就了“梅河大米”有别于其它大米的独特口感与营养保障。",
              },
            ],
          },
        },
      ],

      videoDialog: false,
      videoSrc: "",
      animateClass: [
        { l: "", r: "" },
        { l: "", r: "" },
      ],
    };
  },

  mounted() {
    let that = this;
    // eslint-disable-next-line no-undef
    new Swiper(".index-swiper", {
      loop: false,
      autoplay: {
        delay: 6000,
      },
      // centeredSlides: true,
      // spaceBetween: 30,
      // loopAdditionalSlides: 1,
      navigation: {
        nextEl: ".swiper-navigation-next",
        prevEl: ".swiper-navigation-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },

      on: {
        slideChangeTransitionStart: function () {
          console.log(this.realIndex);
          that.animateClass.forEach((item) => {
            item.l = "";
            item.r = "";
          });
          setTimeout(() => {
            that.animateClass[this.realIndex].l =
              "animate__animated animate__bounceInLeft";
            that.animateClass[this.realIndex].r =
              "animate__animated animate__bounceInRight";
          }, 100);
        },
      },
    });

    window.addEventListener("scroll", () => {
      let scrollH = document.documentElement.scrollHeight;
      let clientH = document.documentElement.clientHeight;
      let scrollTop = document.documentElement.scrollTop;
      if (scrollH - clientH - scrollTop <= 700) {
        this.$refs.shoujiImg.style.bottom = "0";
        this.$refs.shoujiImg.style.opacity = "1";
      }
    });

    setTimeout(() => {
      that.animateClass[0].l = "animate__animated animate__bounceInLeft";
      that.animateClass[0].r = "animate__animated animate__bounceInRight";
    }, 1000);
  },

  methods: {
    playVideo(item, index, src) {
      let iHeight = 600,
        iWidth = 800;
      const iTop = (window.screen.height - 30 - iHeight) / 2; //获得窗口的垂直位置;
      const iLeft = (window.screen.width - 10 - iWidth) / 2; //获得窗口的水平位置;

      switch (index) {
        case 0:
          this.videoSrc = require(`../../video/${src}`);
          this.videoDialog = true;
          break;
        case 3:
          window.open(
            item.href,
            "",
            `height=${iHeight}, width=${iWidth}, top=${iTop}, left=${iLeft}, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no`
          );
          break;
      }
    },
  },
};
</script>

<style scoped lang="less">
@import "index";
</style>
